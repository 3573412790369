/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { mapKeys, mapValues, keyBy, isEmpty } from "lodash";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Switch,
} from "react-router-dom";
import styled from "styled-components";
import Lottie from "react-lottie";
import RocketAnimation from "./assets/lotties/rocket.json";
import CryingAnimation from "./assets/lotties/crying.json";

import Logo from "./assets/botmd_logo.png";
import { device } from "./theme";

const LANG_MAPPING = {
  bengali: "bn",
  mandarin: "zh",
  english: "en",
  tamil: "ta",
  hindi: "hi",
};

const TextBoxMaxWidth = "640px";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 75px;
  border-bottom: 2px solid #e8e9eb;
  transition: all 0.2s ease-in;

  .logo {
    width: 190px;

    @media ${device.laptop} {
      width: 170px;
    }

    @media ${device.mobileL} {
      width: 121px;
    }
  }

  @media ${device.laptop} {
    padding: 15px 20px 15px 40px;
  }

  @media ${device.mobileL} {
    padding: 12px 30px 12px 30px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const OutlineButton = styled.button`
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 150px;
  height: 50px;

  background: #ffffff;
  border: 1px solid #a3a9b1;
  box-sizing: border-box;
  border-radius: 8px;
  color: #697481;

  font-weight: 700;
  font-size: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;

  &:hover {
    background: #f0f3f7;
  }

  &:focus {
    outline: 0;
    border: 3px solid #a3a9b1;
  }

  &:active {
    border: 3px solid #a3a9b1;
    background: #e4e8ec;
    outline: 0;
  }

  @media ${device.laptop} {
    font-size: 12px;
    width: 125px;
    height: 42px;
    padding: 6px 14px;
  }

  @media ${device.mobileL} {
    margin-top: 5px;
    border: 1px solid #256bf6;
    color: #256bf6;
  }
`;

const SolidButton = styled(OutlineButton)`
  width: 125px;
  height: 50px;
  color: #ffffff;
  background: #256bf6;
  border: none;

  &:hover {
    background: #7198ff;
  }

  &:focus {
    outline: 0;
    border: 3px solid #0041c2;
  }

  &:active {
    background: #0041c2;
    outline: 0;
  }

  @media ${device.laptop} {
    font-size: 12px;
    width: 125px;
    height: 42px;
    padding: 6px 14px;
  }

  @media ${device.mobileL} {
    display: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  @media ${device.mobileL} {
    margin: 0 25px;
  }
`;

const HangTight = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 47px;
  line-height: 57px;
  text-align: center;
  color: #111824;
  margin-top: 120px;

  @media ${device.mobileL} {
    margin-top: 80px;
    font-size: 24px;
    line-height: 32px;
  }
`;

const RedirectMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #111824;
  max-width: ${TextBoxMaxWidth};

  @media ${device.mobileL} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const LottieContainer = styled.div`
  display: inline-block;
  max-width: ${(props) => (props.hasAPath ? TextBoxMaxWidth : "400px")};

  @media ${device.mobileL} {
    max-width: ${(props) => (props.hasAPath ? TextBoxMaxWidth : "300px")};
  }
`;

const FooterMainText = styled.div`
  max-width: ${TextBoxMaxWidth};
  margin-top: 130px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #697481;

  @media ${device.mobileL} {
    font-size: 13px;
    line-height: 16px;
    margin-top: 85px;
  }
`;

const FooterSubText = styled.div`
  max-width: ${TextBoxMaxWidth};
  margin-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a3a9b1;

  @media ${device.mobileL} {
    font-size: 13px;
    line-height: 16px;
  }
`;

const OopsMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 47px;
  line-height: 57px;
  text-align: center;
  color: #111824;
  margin-top: -15px;

  @media ${device.mobileL} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const CheckWithHospitalMessage = styled.div`
  margin-top: 10px;
  max-width: ${TextBoxMaxWidth};
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #111824;

  @media ${device.mobileL} {
    font-size: 13px;
    line-height: 16px;
  }
`;

const App = () => {
  const params = useParams();
  const [landingPages, setLandingPages] = useState();
  const hasAPath = params.path && !landingPages;

  const setLottieOptions = (lottie) => ({
    loop: true,
    autoplay: true,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  async function fetchLandingPages() {
    return new Promise((resolve, reject) => {
      // let env = 'dev' ? "staging" : "sdsd";
      const currLocation = window.location.href;
      let env = "production";
      if (/staging|botmdcare/i.test(currLocation)) {
        env = "staging,production";
      }
      if (/localhost/i.test(currLocation)) {
        env = "staging,production";
      }

      fetch(
        `https://asia-east2-botmdredirect.cloudfunctions.net/listRedirects?env=${env}`
      )
        .then((res) => res.json())
        .then(({ pages }) =>
          resolve(
            mapValues(keyBy(pages, "url"), ({ fields }) => ({
              fields: mapKeys(fields, (val, lang) => {
                return LANG_MAPPING[lang.toLowerCase()]
                  ? LANG_MAPPING[lang.toLowerCase()]
                  : lang;
              }),
            }))
          )
        )
        .catch(reject);
    });
  }

  const handleContactUs = () => {
    window.location = "mailto:hello@botmd.io?subject=Bot MD Care - New Inquiry";
  };

  const handleBotMDHome = () => {
    window.open("http://www.botmd.io/", "_blank");
  };

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const pages = await fetchLandingPages();
        const landingPage = pages[params.path];
        // if (!landingPage) {
        //   throw new Error('Page not found');
        // }
        const formSgRedirect = landingPage.fields.FormSG;
        if (!isEmpty(formSgRedirect)) {
          window.location = formSgRedirect;
        } else {
          setLandingPages(pages);
        }
      } catch (e) {
        setLandingPages({ error: true, pages: {} });
      }
    };
    fetchPages();
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <Header>
        <img className="logo" alt="botmd-logo" src={Logo} />
        <ButtonGroup>
          <OutlineButton onClick={() => handleBotMDHome()}>
            Bot MD Home
          </OutlineButton>
          <SolidButton onClick={() => handleContactUs()}>
            Contact Us
          </SolidButton>
        </ButtonGroup>
      </Header>
      <MainContent>
        {hasAPath && <HangTight>Hang tight!</HangTight>}
        <LottieContainer hasAPath={hasAPath}>
          <Lottie
            isClickToPauseDisabled={true}
            options={
              hasAPath
                ? setLottieOptions(RocketAnimation)
                : setLottieOptions(CryingAnimation)
            }
          />
        </LottieContainer>
        {!hasAPath && <OopsMessage>Oops, your link doesn’t exist!</OopsMessage>}
        {hasAPath ? (
          <RedirectMessage>
            You’ll be redirected to your website in just a few moments.
          </RedirectMessage>
        ) : (
          <CheckWithHospitalMessage>
            You might want to check with your hospital to see why.{" "}
            <span style={{ color: "#256bf6" }}>go.botmd.com</span> short links
            are created to make it easier for Bot MD customers to share their
            links.
          </CheckWithHospitalMessage>
        )}
        {hasAPath && (
          <>
            <FooterMainText>What is go.botmd.com?</FooterMainText>
            <FooterSubText>
              Long URLs are hard to remember. go.botmd.com short links are
              created to make it easier for Bot MD customers to share their
              links.
            </FooterSubText>
          </>
        )}
      </MainContent>
    </>
  );
};

const WARedirect = () => {
  setTimeout(() => {
    window.location = "https://wa.me/6593910846";
  });
};

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/wa">
          <WARedirect />
        </Route>
        <Route path="/:path?">
          <App />
        </Route>
      </Switch>
    </Router>
  );
};
